import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Badge } from './ui/badge';

// Use the worker URL from environment variables based on environment
const API_BASE =
  import.meta.env.VITE_ENVIRONMENT === 'production'
    ? import.meta.env.VITE_PROD_WORKER_URL
    : import.meta.env.VITE_DEV_WORKER_URL;

interface VersionResponse {
  worker: {
    id: string;
    created: string;
  } | null;
  pages: {
    id: string;
    created: string;
  } | null;
}

export function VersionInfo(): React.JSX.Element | null {
  const { data } = useQuery<VersionResponse>({
    queryKey: ['version'],
    queryFn: async () => {
      const res = await fetch(`${API_BASE}/api/version`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!res.ok) throw new Error('Failed to fetch version info');
      return res.json();
    },
    refetchInterval: 60000, // Refetch every minute
  });

  if (!data?.worker && !data?.pages) return null;

  return (
    <div className="fixed bottom-4 right-4 flex gap-2 text-xs text-muted-foreground">
      {data.worker && (
        <Badge variant="outline" className="gap-1">
          <span>Worker</span>
          <span className="font-mono">{data.worker.id}</span>
        </Badge>
      )}
      {data.pages && (
        <Badge variant="outline" className="gap-1">
          <span>Pages</span>
          <span className="font-mono">{data.pages.id}</span>
        </Badge>
      )}
    </div>
  );
}

import { useEffect } from 'react';

const isDevelopment = import.meta.env.DEV;

export function TitleManager() {
  useEffect(() => {
    document.title = isDevelopment ? 'xScraper Dev' : 'xScraper Prod';
  }, []);

  return null;
}

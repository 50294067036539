import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.js';
import { Button } from '@/components/ui/button.js';
import { Input } from '@/components/ui/input.js';
import { Label } from '@/components/ui/label.js';
import { Textarea } from '@/components/ui/textarea.js';
import type { Field } from '@/types/scraping.js';
import { useScrapingConfigs } from '@/hooks/use-scraping-configs.js';
import { useToast } from '@/hooks/use-toast.js';

export default function NewConfig() {
  const navigate = useNavigate();
  const { addConfig } = useScrapingConfigs();
  const { toast } = useToast();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [fields, setFields] = useState<Field[]>([{ name: '', description: '' }]);

  const handleFieldChange = (index: number, updates: Partial<Field>) => {
    setFields(prev => {
      const newFields = [...prev];
      newFields[index] = { ...newFields[index], ...updates };
      return newFields;
    });
  };

  const handleAddField = () => {
    setFields(prev => [...prev, { name: '', description: '' }]);
  };

  const handleRemoveField = (index: number) => {
    setFields(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    try {
      const config = {
        name,
        description,
        url,
        urlPattern: url,
        fields: fields.filter(f => f.name.trim()),
        selectors: {},
        user_id: '',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      await addConfig(config);
      toast({
        title: 'Success',
        description: 'Configuration created successfully',
      });
      navigate('/configs');
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to create configuration',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-3xl font-bold">Create Configuration</h1>
        <p className="text-muted-foreground">Define what data you want to extract from web pages</p>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Configuration Details</CardTitle>
          <CardDescription>
            Enter the basic information about your scraping configuration
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Enter configuration name"
                autoComplete="off"
              />
            </div>

            <div>
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Enter configuration description"
                autoComplete="off"
              />
            </div>

            <div>
              <Label htmlFor="url">URL Pattern</Label>
              <Input
                id="url"
                value={url}
                onChange={e => setUrl(e.target.value)}
                placeholder="Enter URL pattern to scrape"
                autoComplete="url"
              />
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Fields to Extract</CardTitle>
          <CardDescription>
            Define the data fields you want to extract from each page
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <Label>Fields</Label>
              <Button onClick={handleAddField} variant="outline" size="sm">
                Add Field
              </Button>
            </div>

            {fields.map((field, index) => (
              <div key={index} className="flex gap-4">
                <div className="flex-1">
                  <Input
                    value={field.name}
                    onChange={e => handleFieldChange(index, { name: e.target.value })}
                    placeholder="Field name"
                    autoComplete="off"
                  />
                </div>
                <div className="flex-1">
                  <Input
                    value={field.description}
                    onChange={e => handleFieldChange(index, { description: e.target.value })}
                    placeholder="Field description"
                    autoComplete="off"
                  />
                </div>
                <Button
                  onClick={() => handleRemoveField(index)}
                  variant="destructive"
                  size="icon"
                  className="shrink-0"
                >
                  ×
                </Button>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <div className="flex gap-4">
        <Button variant="outline" onClick={() => navigate('/configs')}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Create Configuration</Button>
      </div>
    </div>
  );
}

import React, { type FC, type ReactNode } from 'react';
import { Navigation } from './Navigation.js';

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-background">
      <Navigation />
      <main className="container mx-auto py-6">{children}</main>
    </div>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '@/contexts/auth-context.js';
import { Button } from '@/components/ui/button.js';
import { Avatar, AvatarFallback } from '@/components/ui/avatar.js';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.js';
import { VersionInfo } from '@/components/version-info.js';

export function Navigation() {
  const { user, logout } = useAuthContext();

  return (
    <nav className="border-b">
      <div className="container mx-auto px-4 py-3 flex items-center justify-between">
        <div className="flex items-center space-x-8">
          <Link to="/" className="text-xl font-semibold">
            xScraper
          </Link>
          {user && (
            <div className="flex items-center space-x-6">
              <Link to="/dashboard" className="text-sm text-muted-foreground hover:text-foreground">
                Dashboard
              </Link>
              <Link to="/jobs" className="text-sm text-muted-foreground hover:text-foreground">
                Jobs
              </Link>
              <Link to="/configs" className="text-sm text-muted-foreground hover:text-foreground">
                Configurations
              </Link>
            </div>
          )}
        </div>

        <div className="flex items-center space-x-4">
          <VersionInfo />
          {user ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                  <Avatar className="h-8 w-8">
                    <AvatarFallback>{user.name[0].toUpperCase()}</AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem className="font-normal">
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">Welcome, {user.name}!</p>
                    <p className="text-xs leading-none text-muted-foreground">{user.email}</p>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={logout}>Log out</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <div className="flex items-center space-x-4">
              <Button variant="ghost" asChild>
                <Link to="/login">Log in</Link>
              </Button>
              <Button asChild>
                <Link to="/register">Sign up</Link>
              </Button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.js';
import { Button } from '@/components/ui/button.js';
import { useScrapingConfigs } from '@/hooks/use-scraping-configs.js';

export default function Configs() {
  const { configs } = useScrapingConfigs();

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Scraping Configurations</h1>
          <p className="text-muted-foreground">Manage your scraping configurations</p>
        </div>
        <Button asChild>
          <Link to="/configs/new">Create Configuration</Link>
        </Button>
      </div>

      {configs?.length === 0 ? (
        <Card>
          <CardContent className="py-8">
            <div className="text-center space-y-4">
              <h2 className="text-xl font-semibold">No configurations found</h2>
              <p className="text-muted-foreground">
                Create your first scraping configuration to get started
              </p>
              <Button asChild>
                <Link to="/configs/new">Create Configuration</Link>
              </Button>
            </div>
          </CardContent>
        </Card>
      ) : (
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {configs?.map(config => (
            <Card key={config.id}>
              <CardHeader>
                <CardTitle>{config.name}</CardTitle>
                <CardDescription>{config.description}</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div>
                    <p className="text-sm font-medium">URL Pattern</p>
                    <p className="text-sm text-muted-foreground">{config.urlPattern}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium">Fields</p>
                    <ul className="list-disc list-inside text-sm text-muted-foreground">
                      {config.fields.map(field => (
                        <li key={field.name}>{field.name}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex gap-2">
                    <Button variant="outline" className="w-full">
                      Edit
                    </Button>
                    <Button variant="outline" className="w-full">
                      Run
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}

import { get, post, put, del } from './api.js';
import { API_URL } from './api.js';
import type {
  ScrapingConfig,
  ScrapingConfigInput,
  ScrapingField,
  Job,
  ScrapingResult,
} from '@/types/scraping.js';

// Re-export types
export type { ScrapingConfig, ScrapingConfigInput, Job, ScrapingResult };

interface AnalyzePageParams {
  url: string;
  fields: ScrapingField[];
}

interface AnalyzePageResponse {
  selectors: Record<string, string>[];
}

interface APIResponse<T> {
  data: T;
}

interface JobResponse {
  data: {
    job: Job;
  };
}

interface ResultsResponse {
  data: {
    results: ScrapingResult[];
  };
}

const API_BASE = `${API_URL}/scraping`;

export async function analyzePage(params: AnalyzePageParams): Promise<AnalyzePageResponse> {
  const response = await post<APIResponse<AnalyzePageResponse>>(`${API_BASE}/analyze`, {
    url: params.url,
    fields: params.fields,
  });
  return response.data;
}

export async function getScrapingConfigs(): Promise<ScrapingConfig[]> {
  const response = await get<APIResponse<ScrapingConfig[]>>(`${API_BASE}/configs`);
  return response.data;
}

export async function getScrapingConfig(id: string): Promise<ScrapingConfig> {
  const response = await get<APIResponse<ScrapingConfig>>(`${API_BASE}/configs/${id}`);
  return response.data;
}

export async function createScrapingConfig(
  config: Partial<ScrapingConfig>
): Promise<ScrapingConfig> {
  console.log('Creating scraping config with data:', JSON.stringify(config, null, 2));
  try {
    // Ensure required fields are present
    if (!config.name || !config.urlPattern) {
      throw new Error('Name and URL pattern are required fields');
    }

    // Add required fields if missing
    const configWithDefaults = {
      ...config,
      fields: config.fields || [],
      selectors: config.selectors || {},
    };

    const response = await post<APIResponse<ScrapingConfig>>(
      `${API_BASE}/configs`,
      configWithDefaults
    );

    if (typeof response === 'string') {
      try {
        const parsed = JSON.parse(response);
        return parsed.data;
      } catch (parseError) {
        console.error('Failed to parse response:', parseError);
        throw parseError;
      }
    }
    return response.data;
  } catch (error) {
    console.error('Error creating scraping config:', error);
    if (error instanceof Error) {
      console.error('Error details:', {
        message: error.message,
        name: error.name,
        stack: error.stack,
      });
    }
    throw error;
  }
}

export async function updateScrapingConfig(
  id: string,
  config: Partial<ScrapingConfig>
): Promise<ScrapingConfig> {
  const response = await put<APIResponse<ScrapingConfig>>(`${API_BASE}/configs/${id}`, config);
  return response.data;
}

export async function deleteScrapingConfig(id: string): Promise<void> {
  await del(`${API_BASE}/configs/${id}`);
}

export async function startScraping(configId: string): Promise<{ jobId: string }> {
  console.log('Starting scraping job for config:', configId);
  const response = await post<APIResponse<{ jobId: string; message: string }>>(
    `${API_BASE}/configs/${configId}/run`,
    {}
  );
  console.log('Scraping job started:', response.data);
  return { jobId: response.data.jobId };
}

export async function getScrapingJob(jobId: string): Promise<Job> {
  console.log('Fetching job status:', jobId);
  const response = await get<JobResponse>(`${API_BASE}/jobs/${jobId}`);
  console.log('Job status response:', response.data);
  return response.data.job;
}

export async function getScrapingResults(configId: string): Promise<ScrapingResult[]> {
  console.log('Fetching scraping results for config:', configId);
  const response = await get<ResultsResponse>(`${API_BASE}/configs/${configId}/results`);
  console.log('Scraping results:', response.data);
  return response.data.results;
}

export async function getJob(jobId: string): Promise<Job> {
  console.log('Fetching job:', jobId);
  const response = await get<JobResponse>(`${API_BASE}/jobs/${jobId}`);
  console.log('Job response:', response.data);
  return response.data.job;
}

export async function pauseJob(jobId: string): Promise<Job> {
  console.log('Pausing job:', jobId);
  const response = await post<JobResponse>(`${API_BASE}/jobs/${jobId}/pause`, {});
  console.log('Job paused:', response.data);
  return response.data.job;
}

export async function resumeJob(jobId: string): Promise<Job> {
  console.log('Resuming job:', jobId);
  const response = await post<JobResponse>(`${API_BASE}/jobs/${jobId}/resume`, {});
  console.log('Job resumed:', response.data);
  return response.data.job;
}

export async function cancelJob(jobId: string): Promise<Job> {
  console.log('Canceling job:', jobId);
  const response = await post<JobResponse>(`${API_BASE}/jobs/${jobId}/cancel`, {});
  console.log('Job canceled:', response.data);
  return response.data.job;
}

export async function getScrapingJobs(): Promise<Job[]> {
  const response = await get<APIResponse<Job[]>>(`${API_BASE}/jobs`);
  return response.data;
}

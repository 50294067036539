import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getScrapingConfigs,
  createScrapingConfig,
  updateScrapingConfig,
  deleteScrapingConfig,
  type ScrapingConfig,
  type ScrapingConfigInput,
} from '@/services/scraping.js';
import { useToast } from './use-toast.js';

export function useScrapingConfigs() {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: configs, isLoading } = useQuery<ScrapingConfig[]>({
    queryKey: ['scraping-configs'],
    queryFn: getScrapingConfigs,
    select: data => {
      // Ensure we always return an array
      return Array.isArray(data) ? data : [];
    },
  });

  const addConfig = useMutation({
    mutationFn: (config: ScrapingConfigInput) => createScrapingConfig(config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['scraping-configs'] });
      toast({
        title: 'Success',
        description: 'Configuration created successfully',
      });
    },
    onError: (error: Error) => {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    },
  });

  const updateConfig = useMutation({
    mutationFn: ({ id, config }: { id: string; config: Partial<ScrapingConfig> }) =>
      updateScrapingConfig(id, config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['scraping-configs'] });
      toast({
        title: 'Success',
        description: 'Configuration updated successfully',
      });
    },
    onError: (error: Error) => {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    },
  });

  const deleteConfig = useMutation({
    mutationFn: (id: string) => deleteScrapingConfig(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['scraping-configs'] });
      toast({
        title: 'Success',
        description: 'Configuration deleted successfully',
      });
    },
    onError: (error: Error) => {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    },
  });

  return {
    configs: configs || [],
    isLoading,
    addConfig: addConfig.mutate,
    updateConfig: updateConfig.mutate,
    deleteConfig: deleteConfig.mutate,
  };
}

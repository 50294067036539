import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.js';

export default function Jobs() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Scraping Jobs</CardTitle>
        <CardDescription>Monitor and manage your scraping jobs</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="text-center text-muted-foreground">
          No jobs found. Create a scraping configuration first.
        </div>
      </CardContent>
    </Card>
  );
}

import type { APIError } from '@/types/api.js';

export const isDevelopment = import.meta.env.DEV;
export const API_URL = isDevelopment
  ? 'http://localhost:8787/api'
  : 'https://xscraperapi.xstra.ai/api';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const handleError = (error: unknown): APIError => {
  if (error && typeof error === 'object' && 'error' in error) {
    const apiError = error.error as { message?: string; code?: string; statusCode?: number };
    return {
      message: apiError.message || 'Request failed',
      code: apiError.code || 'UNKNOWN_ERROR',
      statusCode: apiError.statusCode || 500,
    };
  }
  return {
    message: 'Request failed',
    code: 'UNKNOWN_ERROR',
    statusCode: 500,
  };
};

export const get = async <T>(url: string, init?: RequestInit): Promise<T> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...defaultHeaders,
      ...(init?.headers || {}),
    },
    credentials: 'include',
    ...init,
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = '/login';
      throw new Error('Please log in to continue');
    }
    throw handleError(await response.json());
  }
  return response.json();
};

export async function post<T, D extends object = Record<string, unknown>>(
  url: string,
  data?: D
): Promise<T> {
  console.log('Making POST request to:', url);
  console.log('Request data:', data ? JSON.stringify(data, null, 2) : 'undefined');

  const response = await fetch(url, {
    method: 'POST',
    headers: defaultHeaders,
    body: data ? JSON.stringify(data) : undefined,
    credentials: 'include',
  });

  console.log('Response status:', response.status);
  console.log('Response headers:', Object.fromEntries(response.headers.entries()));

  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = '/login';
      throw new Error('Please log in to continue');
    }
  }

  const text = await response.text();
  console.log('Raw response text:', text);
  try {
    return JSON.parse(text);
  } catch (error) {
    console.error('Failed to parse response:', error);
    console.error('Raw response was:', text);
    throw error;
  }
}

export const put = async <T>(url: string, data: unknown, init?: RequestInit): Promise<T> => {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...defaultHeaders,
      ...(init?.headers || {}),
    },
    body: JSON.stringify(data),
    credentials: 'include',
    ...init,
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = '/login';
      throw new Error('Please log in to continue');
    }
    throw handleError(await response.json());
  }
  return response.json();
};

export const del = async <T>(url: string, init?: RequestInit): Promise<T> => {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      ...defaultHeaders,
      ...(init?.headers || {}),
    },
    credentials: 'include',
    ...init,
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = '/login';
      throw new Error('Please log in to continue');
    }
    throw handleError(await response.json());
  }
  return response.json();
};

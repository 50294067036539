'use client';

import { useContext } from 'react';
import { type Toast } from '@/contexts/toast-context-def.js';
import { ToastContext } from '@/contexts/toast-context.js';

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

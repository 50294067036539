import React, { createContext, useCallback, useState } from 'react';
import type { Toast } from './toast-context-def.js';
import { Toaster } from '@/components/ui/toaster.js';

interface ToastContextType {
  toast: (toast: Toast) => void;
}

export const ToastContext = createContext<ToastContextType>({
  toast: () => {},
});

interface ToastProviderProps {
  children: React.ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback((toast: Toast) => {
    const id = toast.id || Math.random().toString();
    setToasts(prev => [...prev, { ...toast, id }]);
  }, []);

  return (
    <ToastContext.Provider value={{ toast: addToast }}>
      {children}
      <Toaster />
    </ToastContext.Provider>
  );
};

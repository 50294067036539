import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { User } from '@/types/auth.js';

const isDevelopment = import.meta.env.DEV;
const API_URL = isDevelopment ? 'http://localhost:8787/api' : 'https://xscraperapi.xstra.ai/api';

const debug = (message: string, ...args: unknown[]) => {
  console.debug(`[Auth] ${message}`, ...args);
};

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Check auth status on mount
    debug('Checking session status');
    fetch(`${API_URL}/auth/session`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(async res => {
        debug('Session check response:', { status: res.status });
        if (!res.ok) {
          const error = await res.json();
          debug('Session check failed:', error);
          return null;
        }
        const data = await res.json();
        debug('Session check successful:', data);
        return data?.data?.user || null;
      })
      .then(user => {
        debug('Setting user state:', user);
        setUser(user);
        setLoading(false);
      })
      .catch(error => {
        debug('Session check error:', error);
        setUser(null);
        setLoading(false);
      });
  }, []);

  const login = async (email: string, password: string) => {
    debug('Attempting login:', { email });
    try {
      const res = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      debug('Login response:', { status: res.status });
      const data = await res.json();
      debug('Login response data:', data);

      if (!res.ok) {
        throw new Error(data.error?.message || 'Login failed');
      }

      if (!data.data?.user) {
        throw new Error('Invalid response from server');
      }

      debug('Login successful:', { user: data.data.user });
      setUser(data.data.user);
      navigate('/');
    } catch (error) {
      debug('Login error:', error);
      throw error;
    }
  };

  const register = async (email: string, password: string, name: string) => {
    debug('Attempting registration:', { email, name });
    try {
      const res = await fetch(`${API_URL}/auth/register`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ email, password, name }),
      });

      debug('Registration response:', { status: res.status });
      const data = await res.json();
      debug('Registration response data:', data);

      if (!res.ok) {
        throw new Error(data.error?.message || 'Registration failed');
      }

      if (!data.data?.user) {
        throw new Error('Invalid response from server');
      }

      debug('Registration successful:', { user: data.data.user });
      setUser(data.data.user);
      navigate('/');
    } catch (error) {
      debug('Registration error:', error);
      throw error;
    }
  };

  const logout = async () => {
    debug('Attempting logout');
    try {
      const res = await fetch(`${API_URL}/auth/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });

      debug('Logout response:', { status: res.status });
      if (!res.ok) {
        const data = await res.json();
        debug('Logout failed:', data);
        throw new Error(data.error?.message || 'Logout failed');
      }

      debug('Logout successful');
      setUser(null);
      navigate('/login');
    } catch (error) {
      debug('Logout error:', error);
      throw error;
    }
  };

  return {
    user,
    isAuthenticated: !!user,
    loading,
    login,
    register,
    logout,
  };
}

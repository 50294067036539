import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from '@/contexts/AuthContext.js';
import { ProtectedRoute } from '@/components/protected-route.js';
import { Layout } from '@/components/layout/index.js';
import { TitleManager } from '@/components/title-manager.js';
import { Home } from '@/pages/Home.js';
import { Login } from '@/pages/auth/Login.js';
import { Register } from '@/pages/auth/Register.js';
import Dashboard from '@/pages/Dashboard.js';
import Jobs from '@/pages/Jobs.js';
import Configs from '@/pages/Configs.js';
import NewConfig from '@/pages/configs/New.js';

export function App() {
  return (
    <AuthProvider>
      <TitleManager />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout>
                <Home />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobs"
          element={
            <ProtectedRoute>
              <Layout>
                <Jobs />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/configs"
          element={
            <ProtectedRoute>
              <Layout>
                <Configs />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/configs/new"
          element={
            <ProtectedRoute>
              <Layout>
                <NewConfig />
              </Layout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
}
